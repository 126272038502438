import {
    updateMeta as sourceUpdateMeta,
} from 'SourceStore/Meta/Meta.action';
import {MetaActionType, PageMeta, UpdateMetaAction} from "Store/Meta/Meta.type";
import { getUrlParam, isHomePageUrl } from 'Util/Url';

//TODO: implement updateMeta
export const updateMeta = (metadata: Partial<PageMeta>): UpdateMetaAction => {
    // console.clear();
    // console.log("Updating metadata:", metadata); // Log the metadata
    // console.log(window.location);
    document.getElementById('canonical')?.remove();
    const {pathname, href} = window.location;

    if (!isHomePageUrl(pathname)) {
        metadata.canonical_url = href;
    }
    // console.log("Updating metadata:", metadata); // Log the metadata
    // if ('canonical_url' in metadata) {
    //     console.log('canonical_url exists:', metadata.canonical_url);
    // } else {
    //     console.log('canonical_url does not exist');
    // }

    return {
        type: MetaActionType.UPDATE_META,
        payload: metadata,
    };
};
